import React from 'react'
import { Route, Switch } from 'react-router'
import { connect } from 'react-redux'

import Sessions from './pages/sessions/Sessions.jsx'
import Registrations from './pages/registrations/Registrations.jsx'
import ForgottenPassword from './pages/forgotten-password/ForgottenPassword.jsx'
import ResetPassword from './pages/reset-password/ResetPassword.jsx'

function create(scope, url) {
    return scope ? `${scope}${url}` : url
}

class Auth extends React.Component {
    render() {
        const { className, scope } = this.props
        return (
            <div className={className}>
                <Switch>
                    <Route exact path={create(scope, '/')} component={Sessions} />
                    <Route exact path={create(scope, '/sign-in')} component={Sessions} />
                    <Route exact path={create(scope, '/sign-up')} component={Registrations} />
                    <Route exact path={create(scope, '/forgotten-password')} component={ForgottenPassword} />
                    <Route exact path={create(scope, '/reset-password')} component={ResetPassword} />
                </Switch>
            </div>
        )
    }
}

export default Auth