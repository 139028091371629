import React from 'react'
import { Route, Switch } from 'react-router'

import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import FittedImage from 'components/fitted-image/FittedImage.jsx'
import Hidden from '@material-ui/core/Hidden'

import AuthBox from 'components/auth-page/components/auth-box/AuthBox.jsx'

import withWidth from '@material-ui/core/withWidth'

import img from './images/img.jpg'

import './auth.sass'

const styles = theme => ({
    media: {
        [theme.breakpoints.down('xs')]: {
            height: 200
        },
        [theme.breakpoints.up('sm')]: {
        },
    },
});

const mapStateToProps = state => {
    const { authScope } = state
    return {authScope}
};

const mapDispatchToProps = dispatch => ({

});

class Auth extends React.Component {
    render () {
        const { classes, width, authScope } = this.props
        return (
            <Grid container spacing={0} className='auth-container' justify="center" alignItems="center">
                <Hidden only={['xs']}>
                    <AuthBox containerClass='log-in-container' className='auth-box' title='Log in to Akou' />
                </Hidden>
                <Grid item xs={12} sm={6} md={8} className={['sm', 'md', 'lg', 'xl'].includes(width) ? 'fill-hack' : ''}>
                    <FittedImage fit="cover" src={img} className={classes.media} />
                </Grid>
                <Hidden only={['sm', 'md', 'lg', 'xl']}>
                    <AuthBox containerClass='log-in-container' className='auth-box' title='Log in to Akou' />
                </Hidden>
            </Grid>

        )
    }
}

Auth.propTypes = {
};

export default withStyles(styles)(withWidth()(Auth));