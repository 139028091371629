import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import FormExtractor from '@dolnpm/form-extractor'
import {DataField} from '@dolnpm/data-fields-for'
import OmniLink from '@dolnpm/omni-link'

import Button from '@material-ui/core/Button';

const mapStateToProps = state => {
    const {fields, signInLink, deviseLinks} = state
    return {
        fields,
        signInLink,
        deviseLinks,
    }
};

const mapDispatchToProps = dispatch => ({});

class ForgottenPassword extends React.Component {
    render() {
        const { signInLink } = this.props
        return (
            <FormExtractor handle='forgotten-password-form'>
                <DataField method='email' name='account' fields={this.props.fields} type='text' label='Email' />
                <Button variant="contained" color='primary' type="submit" fullWidth={true}>
                    Send Reminder
                </Button>
                <OmniLink to={signInLink.href} link={signInLink} />
                {this.props.deviseLinks.map((link, index) => {
                    return (
                        <OmniLink key={link.href} to={link.href} link={link} />
                    )
                })}
            </FormExtractor>
        )
    }
}

ForgottenPassword.propTypes = {
    fields: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgottenPassword);