import React from 'react'
import PropTypes from 'prop-types'

import Grid from '@material-ui/core/Grid'
import Auth from 'components/auth/Auth.jsx'

class AuthBox extends React.Component {
    render () {
        const { containerClass, className, title, scope } = this.props
        return (
            <Grid item xs={12} sm={6} md={4} className={containerClass}>
                <div className={className}>
                    <h2>{title}</h2>
                    <div>
                        <Auth scope={scope} />
                    </div>
                </div>
            </Grid>

        )
    }
}

AuthBox.propTypes = {
};

export default AuthBox;